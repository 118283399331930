.title {
    text-align: center;
    h1 {
        color: #F24259;
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
    }
}
.sub_title {
    text-align: center;
    margin-top: 17px;
    p {
        font-size: 16px;
        font-weight: 400;
        align-items: center;
        font-style: normal;
        line-height: 19.5px;
        font-optical-sizing: auto;
        font-family: "Montserrat", sans-serif;
    }
}