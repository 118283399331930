main {
    .title {
        h1 {
            font-size: 20px;
            color: #F24259;
            font-weight: 600;
            line-height: 30px;
            font-style: normal;
            text-transform: uppercase;
            font-optical-sizing: auto;
            font-family: "Inter", sans-serif;
            font-variation-settings: "slnt" 0;
        }
    }
    .toogle_menu {
        gap: 5px;
        width: 313px;
        height: 39px;
        display: flex;
        padding: 3px 3px;
        border-radius: 17px;
        text-align: center;
        margin: 0 auto;
        margin-top: 15px;
        justify-content: center;
        background-color: #fff;
        box-shadow: inset 0px 0px 14px 1px #0000003B;
        button {
            width: 156px;
            height: 33px;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            border-radius: 17px;
            background-color: #fff;
            font-optical-sizing: auto;
            padding: 0px, 46px, 0px, 46px;
            font-family: "Montserrat", sans-serif;  
        }
        .active {
            box-shadow: 0px 0px 2px 2px #75C1FF
        }
    }
    .chats {
        display: grid;
        padding-bottom: 70px;
        grid-template-rows: auto;
        .chat {
            height: 105px;
            padding: 10px;
            display: grid;
            margin: 15px 38px 0px;
            border-radius: 15px;
            align-items: center;
            grid-template-rows: 1fr 1fr;
            box-shadow: 0px 0px 8px 1px #86C9FF;
            h2 {
                font-size: 14px;
                font-weight: 500;
                text-align: left;
                line-height: 17px;
                font-optical-sizing: auto;
                font-family: "Montserrat", sans-serif;
            }
            a {
                text-align: right;
                button {
                    color: #F24259;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16px;
                    background: none;
                }
                svg {
                    color: #F24259;
                }
            }
        }
    }
    .not_exist_text {
        margin-top: 15px;
        p {
            font-size: 15px;
            font-family: "Inter", sans-serif;
            font-variation-settings: "slnt" 0;
        }
    }
}