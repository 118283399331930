.welcome_info {
    padding: 30px 20px;
    text-align: center;
    .welcome_info__img {
        position: relative;
        :first-child {
            width: 260px;
            position: relative;
        }
        :last-child {
            top:50%;
            left:50%;
            width: 260px;
            position: absolute;
            transform:translate(-50%, -50%);
        }
    }
    .welcome_info__btn {
        margin-top: 40px;
        button {
            cursor: pointer;
            color: #fff;
            font-size: 14px;
            line-height: 17.07px;
            gap: 10px;
            height: 43px;
            width: 222px;
            border-radius: 17px;
            font-weight: 550;
            text-transform: uppercase;
            background: linear-gradient(97.12deg, #F24259 -49.11%, #D63D51 -8.13%, #FF3F58 63.62%, #C00019 141.66%);
        }
    }
    .welcome_info__services {
        margin-top: 40px;
        ul {
            display: grid;
            grid-gap: 19px;
            justify-content: center;
            li {
                gap: 5px;
                display: flex;
                align-items: center;
                justify-content: left;
                svg {
                    width: 17px;
                    height: 17px;
                    color: #e79a22;
                    border-radius: 5px;
                }
                p {
                    font-weight: 600;
                    color: black;
                    text-align: left;
                    font-size: 18px;

                    .important {
                        color: #F24259;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}