.main_btn {
    margin-top: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    line-height: 17.07px;
    gap: 10px;
    height: 40px;
    width: 202px;
    border-radius: 17px;
    font-weight: 550;
    text-transform: uppercase;
    background: linear-gradient(97.12deg, #F24259 -49.11%, #D63D51 -8.13%, #FF3F58 63.62%, #C00019 141.66%);
}

.main_btn_text {
    background: none;
    color: #F24259;
    font-optical-sizing: auto;
    font-weight: 500;
    font-family: "Inter", sans-serif;
}