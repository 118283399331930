.service {
    padding: 0 20px;
    margin-top: 11px;
    .service__title {
        h1 {
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            font-optical-sizing: auto;
            font-variation-settings: "wdth" 100;
            font-family: "Open Sans", sans-serif;
        }
    }
    .service__choose {
        display: flex;
        margin-top: 20px;
        align-items: center;
        justify-content: space-between;
        p {
            font-style: normal;
            font-optical-sizing: auto;
            font-variation-settings: "wdth" 100;
            font-family: "Open Sans", sans-serif;
        }
        button {
            width: 70px;
            height: 40px;
            color: #fff;
            padding: 0 5px;
            border-radius: 8px;
            background: #35a3fb;
        }
    }
    .service__description {
        margin-top: 30px;
        text-align: center;
        p {
            font-size: 15px;
            font-style: normal;
            font-optical-sizing: auto;
            font-variation-settings: "wdth" 100;
            font-family: "Open Sans", sans-serif;
        }
    }
    form {
        padding: 10px;
        display: grid;
        margin-top: 8px;
        grid-template-rows: auto;
        textarea {
            overflow: hidden;
            height: 105px;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0px 0px 8px 1px #86C9FF;
        }
        button {
            width: 160px;
            color: #fff;
            padding: 14px;
            font-weight: 600;
            border-radius: 8px;
            margin: 18px auto auto;
            background-color: #F24259;
        }
    }
    .service__background_dark {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.1;
        top: 0;
        left: 0;
        opacity: 0.2;
        background: #000000;
    }
    .service__choose_service {
        left: 0;
        bottom: 0;
        width: 100%;
        height: 450px;
        padding: 10px;
        position: absolute;
        background: #fff;
        border-radius: 15px 15px 0 0;
        h2 {
            font-size: 17px;
            font-weight: 500;
            font-style: normal;
            font-optical-sizing: auto;
            font-variation-settings: "wdth" 100;
            font-family: "Open Sans", sans-serif;
        }
        ul {
            display: grid;
            padding: 18px;
            grid-gap: 30px;
            grid-template-rows: auto;
            li {
                font-size: 17px;
                font-weight: 400;
                font-style: normal;
                font-optical-sizing: auto;
                font-variation-settings: "wdth" 100;
                font-family: "Open Sans", sans-serif;
            }
        }
    }
}