.select_box {
    margin: 24px auto 0;
    position: relative;

    input {
        width: 100%;
        outline: none;
        font-size: 1.1rem;
        padding: 1rem .6rem;
        border: .1rem solid transparent;
    }
}

input[type="tel"] {
    border-radius: 0 .5rem .5rem 0;
}

.select_box input:focus {
    border: 0.1rem solid var(--primary);
}

.selected_option {
    display: grid;
    grid-template-columns: 10% 16% 1fr;
    overflow: hidden;
    align-items: center;
    border-radius: .5rem;
    border: 1px solid #6287E8;
    justify-content: space-between;
    strong {
        width: 0;
    }
    div {
        gap: 5px;
        height: 24px;
        display: flex;
        cursor: pointer;
        position: relative;
        text-align: center;
        align-items: center;
        justify-content: center;

        &::after {
            content: "";
            top: 50%;
            width: .8rem;
            right: .8rem;
            height: .8rem;
            transition: .2s;
            position: absolute;
            transform: translateY(-50%) rotate(45deg);
            border-right: 0.12rem solid var(--primary);
            border-bottom: 0.12rem solid var(--primary);
        }

        &.active::after {
            transform: translateY(-50%) rotate(225deg);
        }
    }
}


.select_box .options {
    z-index: 10000;
    position: absolute;
    top: 4rem;
    width: 100%;
    background-color: #fff;
    border-radius: .5rem;
    display: none;

    &.active {
        display: block;
    }
}

input.search_box {
    background-color: var(--primary);
    color: black;
    border-radius: .5rem .5rem 0 0;
    padding: 1.4rem 1rem;
}

.select_box ol {
    list-style: none;
    max-height: 23rem;
    overflow: overlay;

    &::-webkit-scrollbar {
        width: 0.6rem;
    }

    &::-webkit-scrollbar-thumb {
        width: 0.4rem;
        height: 3rem;
        background-color: #ccc;
        border-radius: .4rem;
    }

    li {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &.hide {
            display: none;
        }

        &:not(:last-child) {
            border-bottom: .1rem solid #eee;
        }

        &:hover {
            background-color: lightcyan;
        }

        .country_name {
            margin-left: .4rem;
        }
    }
}
