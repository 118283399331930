.support_page {
    padding: 0 20px;
    .title {
        margin-top: 15px;
        text-align: center;
        h1 {
            font-size: 20px;
            color: #F24259;
            font-weight: 600;
            line-height: 30px;
            font-style: normal;
            text-transform: uppercase;
            font-optical-sizing: auto;
            font-family: "Inter", sans-serif;
            font-variation-settings: "slnt" 0;
        }
    }
    .description {
        margin-top: 15px;
        p {
            font-optical-sizing: auto;
            font-family: "Montserrat", sans-serif;
        }
    }
    form {
        margin-top: 22px;
        textarea {
            height: 90px;
            min-height: 90px;
            box-shadow: 0px 0px 8px 1px #86C9FF;
        }
        button {
            float: right;
            width: 133px;
            height: 43px;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            margin-top: 17px;
            border-radius: 12px;
            background: #737272;
            font-optical-sizing: auto;
            font-family: "Montserrat", sans-serif;
        }
    }
    .contact_us {
        width: 100%;
        display: grid;
        height: 292px;
        margin-top: 90px;
        padding: 19px 18px;
        border-radius: 17px;
        grid-template-rows: auto;
        box-shadow: 0px 0px 8px 1px #86C9FF;

        h2 {
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            font-optical-sizing: auto;
            font-family: "Montserrat", sans-serif;
        }
        .contact_us__contact {
            gap: 8px;
            display: flex;
            align-items: center;
            border-top: 1px solid #00000017;
                &:last-child {
                    border-bottom: 1px solid #00000017;
                }
            p {
                color: #000;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.8px;
                font-optical-sizing: auto;
                font-family: "Montserrat", sans-serif;
            }
        }
    }
}