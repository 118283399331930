.title {
    margin-top: 15px;
    text-align: center;
    h1 {
        font-size: 20px;
        color: #F24259;
        font-weight: 600;
        line-height: 30px;
        font-style: normal;
        text-transform: uppercase;
        font-optical-sizing: auto;
        font-family: "Inter", sans-serif;
        font-variation-settings: "slnt" 0;
    }
}
.not_exist_sub_title {
    padding: 0 30px;
    margin-top: 30px;
    text-align: center;
    p {
        font-size: 19px;
        color: #161616;
        line-height: 28px;
        font-optical-sizing: auto;
        font-family: "Montserrat", sans-serif;   
    }
}