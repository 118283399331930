header {
    gap: 6px;
    height: 47px;
    display: flex;
    padding-left: 38px;
    align-items: center;

    img {
        &:first-child {
            width: 17px;
            height: 26px;
        }
    }
}