.invite_friend {
    margin-top: 11px;
    .title {
        text-align: center;
        h1 {
            font-size: 20px;
            color: #F24259;
            font-weight: 600;
            line-height: 30px;
            font-style: normal;
            text-transform: uppercase;
            font-optical-sizing: auto;
            font-family: "Inter", sans-serif;
            font-variation-settings: "slnt" 0;
        }
    }
    .sub_title {
        padding: 0 70px;
        margin-top: 14px;
        text-align: center;
        h2 {
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            font-optical-sizing: auto;
            font-family: "Montserrat", sans-serif;   
        }
    }
    .image {
        margin-top: 32px;
        img {
            width: 156px;
            height: 177px;
        }
    }
    .info_promotion {
        text-align: left;
        margin-top: 31px;
        padding: 0px 40px;
        .info_promotion__title {
            h2 {
                font-size: 20px;
                color: #F24259;
                font-weight: 600;
                line-height: 30px;
                font-style: normal;
                text-transform: uppercase;
                font-optical-sizing: auto;
                font-family: "Inter", sans-serif;
                font-variation-settings: "slnt" 0;
            }
        }
        .info_promotion__steps {
            ul {
                li {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 18px;
                    p {
                        font-optical-sizing: auto;
                        font-family: "Montserrat", sans-serif;
                    }
                    
                    button {
                        gap: 15px;
                        width: 100%;
                        height: 38px;
                        display: flex;
                        background: none;
                        align-items: center;
                        border-radius: 17px;
                        margin: 12px 0 12px 0;
                        justify-content: center;
                        padding: 28px, 46px, 28px, 46px;
                        box-shadow: 0px 0px 8px 1px #86C9FF;

                        p {
                            color: #000;
                            font-weight: 600;
                        }
                        span {
                            font-size: 22px;
                            color: #F24259;
                        }
                    }
                }
            }
        }
        .info_promotion__rules {
            margin-top: 16px;
            p {
                text-decoration: underline;
                font-optical-sizing: auto;
                font-family: "Montserrat", sans-serif;
            }
        }
    }
}