main {
    padding-bottom: 15px;
    h1 {
        font-size: 20px;
        color: #F24259;
        font-weight: 600;
        line-height: 30px;
        font-style: normal;
        text-transform: uppercase;
        font-optical-sizing: auto;
        font-family: "Inter", sans-serif;
        font-variation-settings: "slnt" 0;
    }
    .areas_law {
        margin-top: 17px;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
        justify-items: center;
        grid-row-gap: 13px;
        justify-content: center;
    }
}