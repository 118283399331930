main {
    .shadow_back {
        right: 0;
        top: 160px;
        z-index: -10;
        opacity: 0.4;
        position: absolute;
    }
    .info_notify {
        gap: 10px;
        display: flex;
        margin-top: 5px;
        justify-content: center;
    }
    .info_services {
        margin-top: 15px;
        h1 {
            font-size: 23px;
            color: #6287E8;
            font-weight: 600;
            line-height: 32px;
            text-align: center;
            font-style: normal;
            font-optical-sizing: auto;
            font-variation-settings: "wdth" 100;
            font-family: "Open Sans", sans-serif;
        }
        .consultations {
            display: grid;
            margin-top: 32px;
            grid-row-gap: 13px;
            text-align: center;
            justify-items: center;
            grid-column-gap: 13px;
            justify-content: center;
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .info_our_services {
        margin-top: 30px;
        width: 100%;
    }
    .info_invite_friend {
        margin-top: 45px;
        a {
            padding: 20px;
            width: 310px;
            height: 150px;
            display: flex;
            margin: 0 auto;
            align-items: center;
            border-radius: 13px;
            background-color: #fff;
            box-shadow: 0px 0px 8px 1px #86C9FF;
            img {
                width: 86px;
                height: 97px;
            }
            p {
                font-size: 17px;
                text-align: left;
                font-weight: 600;
                color: #6287E8;
                font-optical-sizing: auto;
                font-family: "Montserrat", sans-serif;
            }
        }
    }
    .info_our_services {
        // padding: 0 40px;
        padding-bottom: 20px;
        h1 {
            font-size: 19px;
            color: #F24259;
            font-weight: 700;
            font-style: normal;
            text-transform: uppercase;
            font-optical-sizing: auto;
            font-variation-settings: "wdth" 100;
            font-family: "Open Sans", sans-serif;
        }
        .services_block {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
    }
}