footer {
    left: 50%;
    width: 90%;
    bottom: 0;
    z-index: 100000;
    height: 60px;
    position: fixed;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    transform: translate(-50%, 0);
    nav {
        height: 100%;
        ul {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            li {
                a {
                    display: flex;
                    align-items: center;
                    span {
                        svg {
                            width: 20px;
                            height: 20px;
                            color: #F24259;
                        }
                    }
                    p {
                        color: #000;
                        font-size: 14px;
                        line-height: 17px;
                        font-weight: 400;
                        font-optical-sizing: auto;
                        font-family: "Montserrat", sans-serif;
                    }
                }
                .active {
                    gap: 4px;
                    background-color: #F2425936;
                    padding: 6px 20px;
                    border-radius: 17px;
                }
            }
        }
    }
}