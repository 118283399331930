.relative_main {
    position: relative;
}

main {
    margin-top: 11px;
    text-align: center;
    .shadow_back {
        right: 0;
        bottom: 100px;
        z-index: -10;
        opacity: 0.4;
        width: 360px;
        height: 500px;
        position: absolute;
    }
    .title {
        h1 {
            font-size: 20px;
            color: #F24259;
            font-weight: 600;
            line-height: 30px;
            font-style: normal;
            text-transform: uppercase;
            font-optical-sizing: auto;
            font-family: "Inter", sans-serif;
            font-variation-settings: "slnt" 0;
        }
    }
    .not_exist_sub_title {
        margin-top: 15px;
        p {
            font-size: 19px;
            color: #161616;
            line-height: 28px;
            font-optical-sizing: auto;
            font-family: "Montserrat", sans-serif;   
        }
    }
    .button_block {
        button {
            border-radius: 8px;
            text-transform: none;
            background: #F24259;
        }
    }
    .img_block {
        left: 50%;
        bottom: 62px;
        position: absolute;
        transform: translate(-50%, 10px);
    }
    .exist_services {
        display: grid;
        grid-gap: 15px;
        margin-top: 20px;
        padding-bottom: 120px;
        grid-template-rows: auto;
        .service {
            width: 350px;
            padding: 15px;
            margin: 0 auto;
            text-align: left;
            border-radius: 15px;
            box-shadow: 0px 0px 8px 1px #86C9FF;
            .service__start_date {
                font-size: 16px;
                font-weight: 500;
                color: #858585;
                font-optical-sizing: auto;
                font-family: "Montserrat", sans-serif; 
            }
            h2 {
                color: #000;
                margin-top: 2px;
                font-size: 17px;
                font-weight: 500;
                font-optical-sizing: auto;
                font-family: "Montserrat", sans-serif; 
            }
            ul {
                grid-gap: 6px;
                display: grid;
                margin-top: 3px;
                grid-template-rows: repeat(3, 1fr);
                li {
                    display: flex;
                    padding: 10px;
                    border-radius: 10px;
                    align-items: center;
                    background: #e0e7fb;
                    justify-content: space-between;
                    .service_info {
                        gap: 3px;
                        display: flex;
                        align-items: center;
                        svg {
                            font-size: 20px;
                            color: #6287E8;
                        }
                        p {
                            color: #000;
                            font-size: 13px;
                            font-optical-sizing: auto;
                            font-family: "Montserrat", sans-serif; 
                        }
                        .circle_null {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            background-color: #6287E8;
                        }
                        .quantity_zero {
                            text-decoration: line-through;
                        }
                    }
                    .service__quantity {
                        font-size: 11px;
                        color: #414040;
                    }
                }
            }
            .service__info_end {
                margin-top: 10px;
                p {
                    color: #000;
                    font-optical-sizing: auto;
                    font-family: "Montserrat", sans-serif;
                    span {
                        font-weight: 500;
                    } 
                }
            }
            .service__start {
                margin-top: 8px;
                text-align: center;
                button {
                    color: #fff;
                    font-weight: 600;
                    padding: 15px 50px;
                    border-radius: 10px;
                    background-color: #F24259;
                }
            }
        }
        .add_service {
            left: 50%;
            bottom: 60px;
            position: absolute;
            transform: translate(-50%, 0);
            a {
                svg {
                    font-size: 45px;
                    color: #F24259;
                    border-radius: 15px;
                }
            }
        }
    }
}