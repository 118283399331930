.auth {
    text-align: center;
    padding: 70px 45px;
    form {
        .PhoneInput {
            width: 100px;
            display: flex;
        }
        .data_handling {
            margin-top: 10px;
            .important {
                color: #F24259;
            }

            p {
                text-align: left;
                font-size: 10px;
                font-weight: 400;
                align-items: center;
                font-style: normal;
                line-height: 19.5px;
                font-optical-sizing: auto;
                font-family: "Montserrat", sans-serif;
            }
        }
        button {
            margin-top: 10px;
            cursor: pointer;
            color: #fff;
            font-size: 14px;
            line-height: 17.07px;
            gap: 10px;
            height: 40px;
            width: 202px;
            border-radius: 17px;
            font-weight: 550;
            text-transform: uppercase;
            background: linear-gradient(97.12deg, #F24259 -49.11%, #D63D51 -8.13%, #FF3F58 63.62%, #C00019 141.66%);
        }
    }
}


