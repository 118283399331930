.consultations__elem {
    width: 150px;
    height: 130px;
    display: grid;
    padding: 10px 5px;
    border-radius: 13px;
    align-items: center;
    justify-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 8px 1px #86C9FF;
    p {
        color: #000;
        font-size: 11px;
        font-optical-sizing: auto;
        font-family: "Montserrat", sans-serif;
    }
    a {
        color: #000;
    }
}

.consultations__all {
    width: 150px;
    padding: 28px;
    height: 130px;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    position: relative;
    border-radius: 13px;
    font-optical-sizing: auto;
    background-color: #6287E8;
    box-shadow: inset 0px 0px 14px 1px #000;
    font-family: "Montserrat", sans-serif;

    p {
        color: #fff;
    }
    span {
        right: 6px;
        bottom: 9px;
        color: #000;
        position: absolute;
    }
}