main {
    .service_info {
        padding: 0 20px;
        .description {
            margin-top: 30px;
            h1 {
                text-transform: none;
            }
            p {
                font-size: 14px;
                margin-top: 30px;
                text-align: left;
                font-weight: 300;
                line-height: 19.5px;
                font-optical-sizing: auto;
            }
        }
        .our_services_block {
            margin-top: 30px;
            h2 {
                text-align: left;
                font-size: 20px;
                color: #F24259;
                font-weight: 600;
                line-height: 30px;
                font-style: normal;
                text-transform: uppercase;
                font-optical-sizing: auto;
                font-family: "Inter", sans-serif;
                font-variation-settings: "slnt" 0;
            }
            .our_services {
                display: flex;
                overflow-y: hidden;
                max-width: 100%;
                gap: 10px;
                .our_services__service {
                    min-width: 313px;
                    min-height: 200px;
                    margin-top: 15px;
                    border-radius: 21px;
                    padding: 15px 12px 0px;
                    border: 1px solid #F73851;
                    box-shadow: 6px 2px 8px -4px #F73851;
                    .title {
                        text-align: left;
                        font-weight: 600;
                        font-style: normal;
                        font-optical-sizing: auto;
                        font-variation-settings: "wdth" 100;
                        font-family: "Open Sans", sans-serif;
                    }
                    .line {
                        height: 1px;
                        margin: 23px 0px 10px;
                        background-color: #F73851;
                    }
                    .price {
                        font-size: 16px;
                        font-weight: 600;
                        text-align: left;
                        font-style: normal;
                        font-optical-sizing: auto;
                        font-variation-settings: "wdth" 100;
                        font-family: "Open Sans", sans-serif;
                    }
                    .validity {
                        margin-top: 20px;
                        text-align: left;
                        font-family: "Montserrat", sans-serif;
                        span {
                            font-weight: bold;
                        }
                    }
                    .actions {
                        display: flex;
                        padding: 0 10px;
                        margin-top: 22px;
                        align-items: center;
                        justify-content: space-between;                
                        span {
                            font-size: 23px;
                            color: #F24259;
                        }
                        button {
                            width: 95px;
                            height: 40px;
                            color: #fff;
                            border-radius: 30px;
                            background: #F24259;
                            font-family: "Montserrat", sans-serif;
                        }
                    }
                }   
            }
        }
        .better_together_block {
            margin-top: 22px;
            h2 {
                text-align: left;
                font-size: 20px;
                color: #F24259;
                font-weight: 600;
                line-height: 30px;
                font-style: normal;
                text-transform: uppercase;
                font-optical-sizing: auto;
                font-family: "Inter", sans-serif;
                font-variation-settings: "slnt" 0;
            }
            .better_services {
                display: flex;
                overflow-y: hidden;
                max-width: 100%;
                gap: 10px;
                .better_services__service {
                    min-width: 313px;
                    margin-top: 15px;
                    min-height: 370px;
                    position: relative;
                    border-radius: 21px;
                    padding: 15px 12px 0px;
                    border: 1px solid #F73851;
                    box-shadow: 6px 2px 8px -4px #F73851;
                    .title {
                        text-align: left;
                        font-weight: 600;
                        font-style: normal;
                        font-optical-sizing: auto;
                        font-variation-settings: "wdth" 100;
                        font-family: "Open Sans", sans-serif;
                    }
                    .line {
                        height: 1px;
                        margin: 15px 0px 10px;
                        background-color: #F73851;
                    }
                    .price {
                        font-size: 16px;
                        font-weight: 600;
                        text-align: left;
                        font-style: normal;
                        font-optical-sizing: auto;
                        font-variation-settings: "wdth" 100;
                        font-family: "Open Sans", sans-serif;
                    }
                    .validity {
                        margin-top: 20px;
                        text-align: left;
                        font-family: "Montserrat", sans-serif;
                        span {
                            font-weight: bold;
                        }
                    }
                    .actions {
                        margin-top: 9px;
                        padding: 0 5px;
                        ul {
                            display: grid;
                            grid-row-gap: 7px;
                            grid-template-rows: auto;
                            li {
                                gap: 5px;
                                display: flex;
                                align-items: center;
                                span {
                                    font-size: 18px;
                                    color: #F24259;
                                }
                                p {
                                    font-size: 12px;
                                    text-align: left;
                                    font-family: "Montserrat", sans-serif;
                                }
                            }
                        }
                        .actions__btn {
                            bottom: 10px;
                            right: 22px;
                            position: absolute;
                            button {
                                width: 95px;
                                height: 40px;
                                color: #fff;
                                border-radius: 30px;
                                background: #F24259;
                                font-family: "Montserrat", sans-serif;
                            }
                        }
                    }
                }
            }
        }
    }
}