.legal_info {
    margin-top: 18px;
    padding: 0 31px;
    h1 {
        font-size: 19px;
        font-weight: 600;
        line-height: 21.79px;
        text-transform: uppercase;
        font-optical-sizing: auto;
        font-variation-settings: "wdth" 100;
        font-family: "Open Sans", sans-serif;
    }
    ul {
        display: grid;
        align-items: center;
        grid-template-rows: repeat(5, 1fr);
        p {
            padding: 14px 0;
            border-top: 1px solid #00000017;
            &:last-child {
                border-bottom: 1px solid #00000017;
            }
            font-size: 15px;
            font-weight: 400;
            color: #1E1E1E;
            line-height: 17px;
            font-optical-sizing: auto;
            font-family: "Montserrat", sans-serif;
        }
    }
}