.profile {
    .profile_info {
        padding: 0px 11px;
        gap: 13px;
        width: 350px;
        display: flex;
        height: 149px;
        margin: 0 auto;
        margin-top: 15px;
        align-items: center;
        border-radius: 17px;
        box-shadow: 0px 0px 8px 1px #86C9FF;
        
        ul {
            row-gap: 5px;
            display: grid;
            grid-template-rows: repeat(3, 1fr);
            li {
                gap: 4px;
                display: flex;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                }
                p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19.5px;
                    letter-spacing: -1.9%;
                    font-optical-sizing: auto;
                    font-family: "Montserrat", sans-serif;
                }
            }
            .profile_info_user_id {
                svg {
                    color: #e98410;
                }
            }
            .profile_info_user {
                svg {
                    color: #5be232;
                }
            }
            .profile_info_username {
                svg {
                    color: #e92b35;
                }
            }
        }
    }
    .profile_menu {
        margin-top: 30px;
        padding: 0 20px;
        ul {
            display: grid;
            grid-template-rows: repeat(5, 1fr);
            li {
                padding: 14px 0;
                border-top: 1px solid #00000017;
                &:last-child {
                    border-bottom: 1px solid #00000017;
                }
                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    p {
                        color: #000000;
                        font-size: 17px;
                        font-weight: 550;
                        line-height: 20px;
                        font-optical-sizing: auto;
                        font-family: "Montserrat", sans-serif;
                    }
                    span {
                        font-size: 25px;
                        color: #F24259;
                    }
                }
            }
        }
    }
    .profile_actions {
        margin-top: 20px;
        padding-left: 20px;
        button {
            background: none;
            font-optical-sizing: auto;
            font-family: "Montserrat", sans-serif;
        }
        .profile_actions__exit {
            button {
                color: #F24259;
                font-size: 15px;
                font-weight: 600;
                line-height: 16px;
            }
        }
        .profile_actions__del {
            margin-top: 23px;
            button {
                color: #F24259;
                font-weight: 500;
            }
        }
        .profile_actions__version {
            margin-top: 15px;
            p {
                font-size: 12px;
                font-family: "Montserrat", sans-serif;
            }
        }
    }
}